<template>
  <base-modal
    v-if="show"
    :container-class="'blue two-fa-modal-width'"
    :class="{'two-fa-modal--loading': loading}"
    class="two-fa-modal"
    @close="$emit('close')"
  >
    <template #header>
      <!-- <h2 class="txt txt--bold txt--36px txt--center txt--theme-08">
        {{ 'header' }}
      </h2> -->
    </template>
    <template #body>
      <div class="two-fa-modal__body ml-auto mr-auto">
        <two-fa-input-with-text
          v-if="device"
          :loading="loading"
          :form-key="formKey"
          :error="error || ((serverErrors || {}).device_confirmation_code || [])[0] || ''"
          field="device_confirmation_code"
          class="two-fa-modal__form mt-32 ml-auto mr-auto p-16 pt-32 pl-32--m pr-32--m bor--all bor--radius-02 bor--transp-01"
          @inserted="onFilled('deviceValue', $event)"
          @input="$emit('input')"
        >
          <template #image>
            <img :src="require('@/assets/img/auth/laptop.svg')">
          </template>
          <template #title>
            <h2 class="txt--font-heading txt--bold txt--18px txt--theme-08 mb-0">
              {{ $t('auth.newDeviceTitle') }}
            </h2>
          </template>
          <template #description>
            <p
              class="txt txt--12px txt--theme-08 mb-0"
              v-html="$t('auth.newDeviceText')"
            />
          </template>
          <template #bottom>
            <slot name="deviceConfirmationBottom" />
          </template>
        </two-fa-input-with-text>

        <two-fa-input-with-text
          v-if="twoFa"
          :loading="loading"
          :form-key="formKey"
          :error="error || ((serverErrors || {}).totp || [])[0] || ''"
          field="totp"
          class="two-fa-modal__form mt-32 ml-auto mr-auto p-16 pt-32 pl-32--m pr-32--m bor--all bor--radius-02 bor--transp-01"
          @inserted="onFilled('twoFaValue', $event)"
          @input="$emit('input')"
        >
          <template #image>
            <img :src="require('@/assets/img/auth/google_auth_logo.png')">
          </template>
          <template #title>
            <h2 class="txt--font-heading txt--bold txt--18px txt--theme-08">
              {{ $t('auth.2faAuthTitle') }}
            </h2>
          </template>
          <template #description>
            <p class="txt txt--12px txt--theme-08">
              {{ $t('auth.2faAuthText') }}
            </p>
          </template>
        </two-fa-input-with-text>
        <div
          v-if="loading"
          class="txt--main"
        >
          <exc-loader
            height="14"
          />
        </div>
      </div>
    </template>
  </base-modal>
</template>

<script>
import { ExcLoader } from '@/components/common'

export default {
  name: 'TwoFaModal',
  components: {
    ExcLoader,
    BaseModal: () => import(/* webpackMode: "eager" */ '@/components/base/BaseModal'),
    // GoogleTwoFaCard: () => import(/* webpackMode: "eager" */ '@/components/cards/auth/GoogleTwoFaCard.vue'),
    TwoFaInputWithText: () => import(/* webpackMode: "eager" */ '@/components/inputs/auth/TwoFaInputWithText.vue'),
  },
  props: {
    serverErrors: { type: Object, default: () => ({}) },
    show: { type: Boolean, default: false },
    twoFa: { type: Boolean, default: false },
    device: { type: Boolean, default: false },
    loading: { type: Boolean, required: true },
    formKey: { type: String, default: '' },
    error: { type: String, default: '' },
    clearFields: { type: [Date, Boolean], default: false }, // just for updates
  },

  data () {
    return {
      form: {
        deviceValue: '',
        twoFaValue: '',
      },
    }
  },
  watch: {
    clearFields (v) {
      if (v) {
        this.form.deviceValue = ''
        this.form.twoFaValue = ''
      }
    },
  },
  methods: {
    onFilled (field, value) {
      this.form[field] = value
      this.form = { ...this.form }

      const deviceIsOk = !!(this.device && this.form.deviceValue) || !this.device
      const twoFaIsOk = !!(this.twoFa && this.form.twoFaValue) || !this.twoFa

      if (deviceIsOk && twoFaIsOk) {
        this.$emit('inserted', {
          device: this.form.deviceValue,
          twoFa: this.form.twoFaValue,
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.two-fa-modal {
  &--loading {
    cursor: wait;
  }

  &__body {
    position: relative;
    // margin: auto;
    // padding: 0;
    // @include bp(s) {
    //     padding: toRem(20px) toRem(20px) 0;
    // }
    // @include bp(m) {
    //     padding: toRem(40px) toRem(40px) 0;
    // }
    // @include bp(l) {
    //     padding: toRem(60px) toRem(60px) 0;
    // }
  }

  &__form {
    width: 100%;
    max-width: toRem(446px);

    &:not(:last-child) {
      margin-bottom: space(20);
    }
  }

  // &__digits-container {
  //     margin: -17px;
  //     padding: 17px 0px;
  //     @include bp(m) {
  //         padding: 17px;
  //     }
  // }

  &__loader-container {
    position: absolute;
    top: calc(50% - 70px);
    width: 100%;
    transform: translate(0, 50%);
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__error {
    height: toRem(20px);
  }

  // &__google {
  //     // margin-bottom: toRem(78px);
  // }

  .is-dark & {
    &::v-deep {
      .container-dim__dim {
        background: rgba(#282634, .7);
      }
    }
  }
}
</style>

<style lang="scss">
.two-fa-modal-width {
  width: 100%;
  max-width: 100vw;

  // scale instead of em
  transform: scale(1);

  @include bp(950px) {
    transform: scale(.9);
  }

  @include bp(1366px) {
    transform: scale(.8);
  }
  // @include bp(1367px 1440px) {
  //     transform: scale(.8);
  // }
  @include bp(1440px) {
    transform: scale(.8);
  }

  @include bp(1441px) {
    transform: scale(.9);
  }

  @include bp(1920px) {
    transform: scale(1);
  }

  // max-width
  @include bp(s) {
    max-width: 400px;
  }
  // @include bp(m) {
  //     max-width: 70%;
  // }
  @include bp (1366px) {
    max-width: 440px;
  }

  @include bp(l) {
    max-width: 510px;
  }
}
</style>
