<template>
  <div class="two-fa-input-with-text">
    <div class="two-fa-input-with-text__container">
      <div class="two-fa-input-with-text__title">
        <slot name="title" />
      </div>
      <div class="two-fa-input-with-text__image mt-24">
        <slot name="image" />
      </div>
      <div class="two-fa-input-with-text__text">
        <slot name="text" />
      </div>

      <two-fa-six-digits-input
        :disabled="loading"
        :has-error="!!error"
        class="two-fa-input-with-text__input mt-16"
        @inserted="onInserted"
        @input="onInput"
      />
      <div class="two-fa-input-with-text__error">
        <base-validation-error
          v-if="formKey"
          :form-key="formKey"
          :field="field"
          class="txt--center"
        />
        <div
          v-else-if="error"
          class="txt--red txt--12px txt--center"
        >
          {{ error }}
        </div>
      </div>
    </div>
    <div class="two-fa-input-with-text__bottom mt-16">
      <slot name="bottom" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'TwoFaInputWithText',
  components: {
    TwoFaSixDigitsInput: () => import(/* webpackMode: "eager" */ '@/components/inputs/auth/TwoFaSixDigitsInput__old.vue'),
    BaseValidationError: () => import(/* webpackMode: "eager" */ '@/components/base/BaseValidationError'),
  },
  props: {
    loading: { type: Boolean, default: false },
    formKey: { type: String, default: '' },
    field: { type: String, default: '' },
    error: { type: String, default: '' },
  },
  methods: {
    onInput (val) {
      this.handleUpdate('input', val)
    },

    onInserted (val) {
      this.handleUpdate('inserted', val)
    },

    handleUpdate (event, val) {
      val && val.length > 0 ? this.$emit(event, val) : this.$emit('cleared')
    },
  },
}
</script>

<style lang="scss" scoped>
.two-fa-input-with-text {
  &__container {
    display: grid;
    grid-template-areas: 'title' 'image' 'text' 'input' 'error' 'bottom';
  }

  &__title {
    grid-area: title;
  }

  &__image {
    grid-area: image;
  }

  &__text {
    grid-area: text;
  }

  &__input {
    grid-area: input;
  }

  &__error {
    grid-area: error;
  }

  &__bottom {
    grid-area: bottom;
  }
}
</style>
