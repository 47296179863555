<template>
  <input
    :value="value"
    v-bind="$attrs"
    :class="hasError ? 'bor--red' : ''"
    type="number"
    class="two-fa-input bor--all bor--radius-12 txt txt--14px"
    size="1"
    maxlength="1"
    v-on="$listeners"
  >
</template>

<script>
export default {
  name: 'TwoFaInput',
  props: {
    value: {
      type: [String, Number],
      default: '',
    },
    hasError: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
.two-fa-input {
  background-color: transparent;
  width: toRem(60px);
  transition: all .5s;
  height: toRem(60px);
  padding: 0 toRem(10px);
  text-align: center;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.15);

  @include bp(480, 990) {
    height: 40px !important;
    width: 40px !important;
    margin-right: toRem(10px);
  }

  @include bp(0, 480) {
    height: 36px;
    width: 36px;
    margin-right: toRem(10px);
  }

  &:hover {
    cursor: pointer;
  }

  // remove chrome buttons for numbers
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  // remove firefox buttons for numbers
  &[type='number'] {
    -moz-appearance: textfield;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 1000px transparent inset;
    transition: background-color 5000s ease-in-out 0s;
    background: transparent;
  }
}

.two-fa-input:focus {
  outline: none !important;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.1);
}
</style>
