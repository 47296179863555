<template>
  <input
    :value="value"
    v-bind="$attrs"
    :class="hasError ? 'bor--red' : 'bor--transp-02'"
    type="number"
    class="two-fa-input bor--all bor--radius-01 txt txt--14px txt--color-03"
    size="1"
    maxlength="1"
    v-on="$listeners"
  >
</template>

<script>
export default {
  name: 'TwoFaInput',
  props: {
    value: {
      type: [String, Number],
      default: '',
    },
    hasError: { type: Boolean, default: false },
  },
}
</script>

<style lang="scss" scoped>
.two-fa-input {
  // border: none;
  background-color: transparent;
  max-width: calc(100% / 7);
  width: calc(100% / 7);
  // border-bottom: 3px solid gray;
  transition: all 0.5s;
  height: toRem(map-get($uiKitSizes, m));
  padding: 0 toRem(10px);
  text-align: center;

  &:focus {
    // border-bottom: 3px solid color(main);
    // color: color(main);
    box-shadow: map-get($shadows, '004-main');
    border: 1px solid color(main);
  }

  &:hover {
    cursor: pointer;
  }

  // remove chrome buttons for numbers
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  // remove firefox buttons for numbers
  &[type='number'] {
    -moz-appearance: textfield;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 1000px transparent inset;
    transition: background-color 5000s ease-in-out 0s;
    background: transparent;
  }
}
</style>
