var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"two-fa-six-digits-input"},[_c('div',{staticClass:"two-fa-six-digits-input__inputs"},_vm._l((_vm.inputs),function(el,ind){return _c('two-fa-input',_vm._b({key:el.name,ref:el.name,refInFor:true,staticClass:"two-fa-six-digits-input__input txt txt--16px txt--18px--m txt--center txt--bold",attrs:{"value":el.value,"has-error":_vm.hasError},on:{"input":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.onInput.apply(void 0, [ ind ].concat( argsArray ))},"keyup":[function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"delete",[8,46],$event.key,["Backspace","Delete","Del"])){ return null; }return _vm.onDelete.apply(void 0, [ ind ].concat( argsArray ))},function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"left",37,$event.key,["Left","ArrowLeft"])){ return null; }if('button' in $event && $event.button !== 0){ return null; }return _vm.moveLeft.apply(void 0, [ ind ].concat( argsArray ))},function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"right",39,$event.key,["Right","ArrowRight"])){ return null; }if('button' in $event && $event.button !== 2){ return null; }return _vm.moveRight.apply(void 0, [ ind ].concat( argsArray ))}],"wheel":function($event){$event.preventDefault();$event.stopPropagation();}}},'two-fa-input',_vm.$attrs,false))}),1)])}
var staticRenderFns = []

export { render, staticRenderFns }