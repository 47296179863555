<template>
  <!-- :form-key="formKey" -->
  <component
    v-bind="{ ...$attrs, ...$props }"
    :is="isModal ? 'confirmation-modal' : 'confirmation-page'"
    :show="show"
    :loading="$attrs.loading || loading"
    :error="(mix_serverErrors.error && mix_serverErrors.error[0]) || ''"
    :server-errors="serverErrors"
    :two-fa="twoFa"
    :device="device"
    :email="email"
    :email-code-resend-in="emailCodeResendIn"
    :device-code-resend-in="deviceCodeResendIn"
    :device-confirmation-field="deviceConfirmationField"
    class="confirmation"
    v-on="$listeners"
    @close="onClose"
    @input="onInput"
    @inserted="onInserted"
    @resend-email-code="resendEmailConfirmationCode"
    @resend-device-code="resendDeviceConfirmationCode"
  >
    <template #expandedBlock>
      <slot name="expandedBlock" />
    </template>

    <!-- <template #emailConfirmationBottom>
      <div
        v-if="emailCodeResendIn"
        class="confirmation__buttom"
      >
        <exc-button
          :disabled="resendEmailLeftSeconds > 0 || loading"
          @click.stop.prevent="resendEmailConfirmationCode"
        >
          {{ $t('auth.resendCode') }} {{ resendEmailLeftSeconds > 0 ? `(${resendEmailLeftSeconds})` : '' }}
        </exc-button>
      </div>
    </template> -->
  </component>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { serverFormMixin } from '@/mixins/serverFormMixin'
import MixinNavLangSwitch from '@/mixins/MixinNavLangSwitch'
import { renameKey } from '@/lib/utils'

const TEMP_ID_KEY = 'temp_user_id'

export default {
  name: 'Confirmation',
  components: {
    TwoFaModal: () =>
      import(/* webpackMode: "eager" */ '@/components/modals/TwoFaModal.vue'),
    ConfirmationModal: () =>
      import(
        /* webpackMode: "eager" */ '@/components/confirmation/ConfirmationModal.vue'
      ),
    ConfirmationPage: () =>
      import(
        /* webpackMode: "eager" */ '@/components/confirmation/ConfirmationPage.vue'
      ),
  },
  mixins: [MixinNavLangSwitch, validationMixin, serverFormMixin],
  props: {
    deviceConfirmationField: {
      type: String,
      default: 'device_confirmation_code',
    },

    isModal: { type: Boolean, default: true },
    show: { type: Boolean, default: false },

    // STAGE 1: Device confirmation
    device: { type: Boolean, default: false },
    deviceCodeResendIn: { type: Number, default: null },

    // STAGE 2: 2FA
    twoFa: { type: Boolean, default: false },

    // STAGE 3: Email
    email: { type: Boolean, default: false },
    emailCodeResendIn: { type: Number, default: null },

    // Delete account of user
    isDeletedAccount: { type: Boolean, default: false },

    extendedData: { type: Object, default: () => {} },

    errors: { type: [Object, String], default: () => ({}) },
  },
  data () {
    return {
      loading: false,
      response: {},

      totp: '', // 2FA

      device_confirmation_code: '',
      email_confirmation_code: '',
      resendEmailLeftSeconds: 0,
      resendEmailConfirmationCodeInterval: null,
    }
  },
  computed: {
    errorMessage () {
      return {
        USER_NOT_FOUND: this.$t('auth.userNotFound'),
        WRONG_CODE: this.$t('auth.WrongCode'),
        EXPIRED_CODE: this.$t('auth.ExpiredCode'),
      }
    },

    serverErrors () {
      if (
        this.errors &&
        typeof this.errors === 'object' &&
        Object.keys(this.errors).length > 0
      ) {
        return this.errors
      }

      return this.mix_serverErrors || {}
    },

    currentScheme () {
      const { twoFa, device } = this

      if (twoFa && device) return this.$authentication.loginWithDeviceAndTwoFa

      if (twoFa) return this.$authentication.loginWithTwoFa

      if (device) return this.$authentication.loginWithDevice

      return false
    },
  },

  watch: {
    show: 'formReInit',
  },

  methods: {
    onInput (field) {
      if (this.mix_serverErrorsData?.error) {
        delete this.mix_serverErrorsData.error
      }
      this.mix_clearAllServerErrors()
      this.mix_clearServerError(field)
      this.mix_serverErrorsData[field] = ''

      this.$emit('input', field)
    },

    onClose () {
      this.mix_clearAllServerErrors()
      this.$emit('close')
    },

    formReInit (show) {
      if (!show) return

      this.totp = ''
      this.device_confirmation_code = ''
      // TODO: email?
    },

    async resendRequest (handler, args, cb) {
      this.loading = true
      let response = null
      try {
        response = await handler(args).finally(() => {
          this.loading = false
        })
      } catch (err) {
        response = err.response.data
      }

      if (response.errors) this.mix_setServerErrors(response.errors)

      this.responseHandler(response)
      this.callbackHandler(cb)
    },

    responseHandler (response) {
      if (response && response.message) {
        const deviceCodeResendIn = this.deviceCodeResendIn
          ? this.deviceCodeResendIn
          : null
        return this.$emit('message-handler', {
          message: response.message,
          response,
          codeExpiresIn: deviceCodeResendIn,
        })
      }
    },

    callbackHandler (callback) {
      if (callback && callback instanceof Function) callback()
    },

    resendDeviceConfirmationCode (cb) {
      if (this.$listeners.resend) {
        this.$emit('resend')
        this.callbackHandler(cb)

        return
      }

      this.resendRequest(this.$api.resendDeviceCode, {
        ...this.extendedData,
        [TEMP_ID_KEY]: this.extendedData[TEMP_ID_KEY],
      }, cb)
    },

    resendEmailConfirmationCode (cb) {
      if (this.$listeners.resend) {
        this.$emit('resend')
        this.callbackHandler(cb)

        return
      }

      const { email, [TEMP_ID_KEY]: tempUserId } = this.extendedData

      this.resendRequest(
        this.$api.registerResendCode,
        { email, [TEMP_ID_KEY]: tempUserId },
        cb,
      )
    },

    async onInserted ({ twoFa, device, email }) {
      if (this.$listeners.inserted) return // this.$listeners.inserted({ twoFa, device, email })
      // TODO: refactor this function
      // WTF?

      if (email) {
        return this.validateEmailCode(email)
      }

      this.$v.totp.$touch()

      if (this.isDeletedAccount) {
        const { errors } = await this.$api.confirmDeleteAccount(
          twoFa ? { confirmation_code: device, totp: twoFa } : { confirmation_code: device },
        )

        if (errors) {
          if (errors.message) {
            renameKey(errors, 'message', 'confirmation_code')
          }

          return this.mix_setServerErrors(errors)
        }

        await this.$store.dispatch('profile/auth/logout')
        await this.localePush('/login')
        return null
      }

      const response = await this.validateProp({
        ...(this.twoFa && { totp: twoFa }),
        ...(this.device && { device_confirmation_code: device }),
        ...this.extendedData,
      })

      await this.setAuthTokens(response)
    },

    async validateProp (data) {
      this.loading = true

      let response = null
      try {
        response = await this.currentScheme(data)
        console.log('response::', response)
      } catch (err) {
        console.log('err::', err)
        response = err.response.data
      }

      this.loading = false

      this.responseHandler(response)

      if (response.errors) {
        const errors = { ...this.errors, ...response.errors }
        response.errors.message
          ? this.mix_setServerErrors({ error: response.errors.message })
          : this.mix_setServerErrors(errors)

        return false
      }

      return response
    },

    async setAuthTokens (response) {
      // const config = (response.data && response.data.tokens) ? response.data : response
      if (!response || !response.data) return
      const config = response.data

      if (config.tokens) {
        const redirect = this.$router.currentRoute.query.redirect

        // this.$cookies.set('socketToken', config.token_web_socket, AUTH_TOKEN_COOKIE_OPTIONS)

        // await this.$store.dispatch('profile/auth/setAuthTokens', config.tokens)
        this.$store.dispatch('settings/verification/actionKycStatus')
        // config.locale && await this.MixinNavLangSwitch_setLocale(config.locale)

        // eslint-disable-next-line eqeqeq
        if (this.getRouteBaseName() === 'register') {
          this.localePush('/login')
        } else if (redirect) {
          await this.localePush(redirect)
        } else {
          await this.localePush('/exchange')
        }

        // @TODO: FIX
        // if comes from ieo page - after login redirects back to this page
        // if (this.isFromIeoPage && this.isFromIeoPage.name === this.$routes.ieoListTickerPage.name) {
        //   this.$router.push(this.isFromIeoPage, () => {
        //     this.setIsFromIeoPage(undefined)
        //   })
        // } else {
        //   const goTo = redirect || this.$routes.trade.path
        //   this.$router.push({ path: goTo }, () => {
        //     referralCallback && this.$store.dispatch(referralCallback, {}, { root: true })
        //   })
        // }
      }
    },

    async validateEmailCode (code) {
      if (this.mix_serverErrorsData.error) {
        this.mix_clearAllServerErrors()
      }
      this.loading = true

      try {
        await this.$api.registerConfirmation({
          ...this.extendedData,
          code,
        })
      } catch (err) {
        err.response.data.errors.message
          ? this.mix_setServerErrors({
            error: err.response.data.errors.message,
          })
          : this.mix_setServerErrors({ error: err.response.data.errors.form })

        return
      } finally {
        this.loading = false
      }

      this.localePush('/login')
      this.$emit('close')
    },
  },

  validations () {
    return this.mix_validationsServerWrapper({
      totp: {},
      device_confirmation_code: {},
      email_confirmation_code: {},
    })
  },
}
</script>

<style lang="scss" scoped>
.confirmation {
  &__buttom {
    display: flex;
    justify-content: center;
    align-content: center;
  }
}
</style>
