import { render, staticRenderFns } from "./TwoFaModal.vue?vue&type=template&id=6c48f046&scoped=true&"
import script from "./TwoFaModal.vue?vue&type=script&lang=js&"
export * from "./TwoFaModal.vue?vue&type=script&lang=js&"
import style0 from "./TwoFaModal.vue?vue&type=style&index=0&id=6c48f046&lang=scss&scoped=true&"
import style1 from "./TwoFaModal.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6c48f046",
  null
  
)

export default component.exports