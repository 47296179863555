<template>
  <base-modal
    v-if="show"
    :class="{'confirmation-modal--loading': loading}"
    class="confirmation-modal"
    container-class="blue confirmation-modal-width"
    @close="$emit('close')"
  >
    <template #header>
      <!-- <h2 class="txt txt--bold txt--36px txt--center txt--theme-08">
        {{ 'header' }}
      </h2> -->
    </template>
    <template #body>
      <confirmation-page
        v-bind="{ ...$attrs, ...$props }"
        :show="show"
        :loading="loading"
        v-on="$listeners"
      >
        <slot />
      </confirmation-page>
    </template>
  </base-modal>
</template>

<script>
export default {
  name: 'ConfirmationModal',
  components: {
    BaseModal: () => import(/* webpackMode: "eager" */ '@/components/base/BaseModal'),
    ConfirmationPage: () => import(/* webpackMode: "eager" */ '@/components/confirmation/ConfirmationPage.vue'),
  },
  props: {
    show: { type: Boolean, default: false },
    loading: { type: Boolean, required: true },
  },
}
</script>

<style lang="scss" scoped>
.confirmation-modal {
  &--loading {
    cursor: wait;
  }
}
</style>

<style lang="scss">
.confirmation-modal-width {
    width: 100%;
    max-width: 100vw;

    // scale instead of em
    transform: scale(1);
    @include bp(950px) {
        transform: scale(.9);
    }
    @include bp(1366px) {
        transform: scale(.8);
    }
    @include bp(1440px) {
        transform: scale(.8);
    }
    @include bp(1441px) {
        transform: scale(.9);
    }
    @include bp(1920px) {
        transform: scale(1);
    }

    // max-width
    @include bp(s) {
        max-width: 400px !important;
        min-width: fit-content !important;
    }
    @include bp (1366px) {
        max-width: 440px !important;
    }
    @include bp(l) {
        max-width: 510px !important;
    }
}
</style>
