<template>
  <div
    :class="{
      'confirmation-page--expanded': expanded,
    }"
    class="confirmation-page ml-auto mr-auto"
  >
    <two-fa-input-with-text
      v-if="device"
      v-bind="$attrs"
      :loading="loading"
      :form-key="formKey"
      :error="assignedError"
      :field="deviceConfirmationField"
      class="confirmation-page__form"
      @inserted="onFilled('deviceValue', $event)"
      @cleared="onCleared('deviceValue')"
      @input="onInput(deviceConfirmationField)"
    >
      <template #title>
        <h1
          class="txt txt--26px txt--height-36px txt--weight-700 txt--color-04 txt--36px--l"
        >
          {{ deviceConfirmationTitle || $t('confirmation.newDeviceTitle') }}
        </h1>
      </template>
      <template #image>
        <img :src="require('@/assets/img/auth/laptop.svg')">
      </template>
      <template #text>
        <div
          class="txt txt--14px txt--height-20px txt--weight-400 txt--color-03 txt--16px--l mt-6"
          v-html="deviceConfirmationText || $t('confirmation.newDeviceRules')"
        />
      </template>

      <template #bottom>
        <div v-if="deviceCodeResendIn" class="confirmation-page__button">
          <confirmation-timer
            :resend-left-seconds="resendLeftSeconds"
            :submit-action="resendDeviceConfirmationCode"
          />
        </div>

        <div class="confirmation-page__back-btn-with-loader">
          <exc-button
            v-if="!backBtnDisabled && !twoFa"
            class="mt-32"
            size="s"
            full-width
            outline
            color="dark"
            @click.prevent="$emit('back')"
          >
            <span>
              {{ backBtnText || $t('registration.btnBack') }}
            </span>
          </exc-button>
        </div>
      </template>
    </two-fa-input-with-text>

    <two-fa-input-with-text
      v-if="twoFa"
      v-bind="$attrs"
      :loading="loading"
      :form-key="formKey"
      :error="error || ((serverErrors || {}).totp || [])[0] || ''"
      field="totp"
      class="confirmation-page__form"
      @inserted="onFilled('twoFaValue', $event)"
      @cleared="onCleared('twoFaValue')"
      @input="onInput('totp')"
    >
      <template #title>
        <h1
          class="txt txt--26px txt--height-36px txt--weight-700 txt--color-04 txt--36px--l"
        >
          {{ $t('confirmation.2faTitle') }}
        </h1>
      </template>
      <template #image>
        <img :src="require('@/assets/img/auth/two-fa.svg')">
      </template>
      <template #text>
        <div
          class="txt txt--14px txt--height-20px txt--weight-400 txt--color-03 txt--16px--l mt-6"
        >
          {{ $t('confirmation.2faRules') }}
        </div>
      </template>

      <template #bottom>
        <!--        <div v-if="deviceCodeResendIn" class="confirmation-page__button">-->
        <!--          <confirmation-timer-->
        <!--            :resend-left-seconds="resendLeftSeconds"-->
        <!--            :submit-action="resendDeviceConfirmationCode"-->
        <!--          />-->
        <!--        </div>-->

        <div class="confirmation-page__back-btn-with-loader">
          <exc-button
            v-if="!backBtnDisabled"
            class="mt-32"
            size="s"
            full-width
            outline
            color="dark"
            @click.prevent="$emit('back')"
          >
            <span>
              {{ backBtnText || $t('registration.btnBack') }}
            </span>
          </exc-button>
        </div>
      </template>
    </two-fa-input-with-text>

    <two-fa-input-with-text
      v-if="email"
      v-bind="$attrs"
      :loading="loading"
      :form-key="formKey"
      :error="
        error ||
          ((serverErrors || {}).reset_confirmation_code ||
            (serverErrors || {}).email_confirmation_code ||
            [])[0] ||
          ''
      "
      field="email_confirmation_code"
      class="confirmation-page__form"
      @inserted="onFilled('emailValue', $event)"
      @cleared="onCleared('emailValue')"
      @input="onInput('email_confirmation_code')"
    >
      <template #title>
        <h1
          class="txt txt--36px txt--height-36px txt--bold txt--color-04 mb-14"
        >
          {{ $t('registration.emailConfirmationTitle') }}
        </h1>
      </template>
      <template #image>
        <img :src="require('@/assets/img/auth/email.svg')">
      </template>
      <template #text>
        <div
          class="txt txt--color-03 txt--16px txt--height-20px mb-32"
          v-html="$t('registration.emailConfirmationText')"
        />
      </template>
      <template #bottom>
        <div v-if="emailCodeResendIn">
          <confirmation-timer
            :resend-left-seconds="resendEmailLeftSeconds"
            :submit-action="resendEmailConfirmationCode"
          />
        </div>

        <div class="confirmation-page__back-btn-with-loader">
          <exc-button
            v-if="!backBtnDisabled"
            class="mt-32"
            size="s"
            full-width
            outline
            color="dark"
            @click.prevent="$emit('back')"
          >
            <span>
              {{ backBtnText || $t('registration.btnBack') }}
            </span>
          </exc-button>
        </div>
      </template>
    </two-fa-input-with-text>

    <div
      v-if="expanded && expandedIsShow"
      class="confirmation-page__expanded-block"
    >
      <slot name="expandedBlock" />
    </div>

    <!-- <div class="confirmation-page__back-btn-with-loader"> -->
    <!-- <div v-if="loading" class="txt--main">
        <exc-loader height="14" />
      </div> -->

    <!-- <exc-button
        v-if="!backBtnDisabled"
        outline
        color="white"
        size="m"
        class="confirmation-page__back-btn mt-32"
        @click.prevent="$emit('back')"
      >
        {{ backBtnText || $t('buttons.cancel') }}
      </exc-button>
    </div> -->
  </div>
</template>

<script>
import { ExcButton } from '@/components/common'
import { TIME } from '@/lib/constants'

export default {
  name: 'ConfirmationPage',
  components: {
    ExcButton,
    // BaseModal: () =>
    //   import(/* webpackMode: "eager" */ '@/components/base/BaseModal'),
    // GoogleTwoFaCard: () =>
    //   import(
    //     /* webpackMode: "eager" */ '@/components/cards/auth/GoogleTwoFaCard.vue'
    //   ),
    TwoFaInputWithText: () =>
      import(
        /* webpackMode: "eager" */ '@/components/inputs/auth/TwoFaInputWithText__old.vue'
      ),
    ConfirmationTimer: () =>
      import('@/components/confirmation/ConfirmationTimer.vue'),
  },
  props: {
    serverErrors: {
      type: [Object, String],
      default: () => ({}),
    },

    twoFa: {
      type: Boolean,
      default: false,
    },
    device: {
      type: Boolean,
      default: false,
    },
    email: {
      type: Boolean,
      default: false,
    },

    loading: {
      type: Boolean,
      required: true,
    },
    formKey: {
      type: String,
      default: '',
    },
    error: {
      type: String,
      default: '',
    },

    deviceConfirmationField: {
      type: String,
      default: 'device_confirmation_code',
    },
    deviceConfirmationTitle: {
      type: String,
      default: null,
    },
    deviceConfirmationText: {
      type: String,
      default: null,
    },

    deviceCodeResendIn: {
      type: Number,
      default: null,
    },
    emailCodeResendIn: {
      type: Number,
      default: null,
    },

    backBtnDisabled: {
      type: Boolean,
      default: false,
    },
    backBtnText: {
      type: String,
      default: '',
    },

    extendedData: {
      type: Object,
      default: () => {},
    },

    expanded: {
      type: Boolean,
      default: false,
    },

    headerClasses: {
      type: String,
      default: 'txt--bold txt--18px',
    },
    descriptionClasses: {
      type: String,
      default: 'txt--12px',
    },
  },

  data () {
    return {
      form: {
        deviceValue: '',
        twoFaValue: '',
        emailValue: '',
      },
      resendLeftSeconds: 0,
      resendDeviceConfirmationCodeInterval: null,
      resendEmailLeftSeconds: 0,
      resendEmailConfirmationCodeInterval: null,
    }
  },
  computed: {
    assignedError () {
      const error =
        this.error || (this.serverErrors || {})[this.deviceConfirmationField]

      if (typeof error === 'string') {
        return error
      }

      if (Array.isArray(error) && error.length > 0) {
        return error[0]
      }

      return ''
    },
    expandedIsShow () {
      let i = 0
      if (this.twoFa) ++i
      if (this.device) ++i
      if (this.email) ++i
      return i % 2 === 1
    },
  },
  watch: {
    device (newValue) {
      if (newValue) this.deviceReinit()
    },
    email (newValue) {
      if (newValue) this.emailReinit()
    },
    show (newValue) {
      if (!newValue) this.formReinit()
    },
  },
  created () {
    this.deviceReinit()
  },
  mounted () {
    this.resetResendEmailConfirmationCodeButton()
  },
  methods: {
    onInput (field) {
      this.$emit('input', field)
    },
    onFilled (field, value) {
      this.form[field] = value
      this.form = { ...this.form }

      const deviceIsOk =
        !!(this.device && this.form.deviceValue) || !this.device
      const twoFaIsOk = !!(this.twoFa && this.form.twoFaValue) || !this.twoFa
      const emailIsOk = !!(this.email && this.form.emailValue) || !this.email

      if (deviceIsOk && twoFaIsOk && emailIsOk) {
        this.$emit('inserted', {
          device: this.form.deviceValue,
          twoFa: this.form.twoFaValue,
          email: this.form.emailValue,
        })
      }
    },
    onCleared (field) {
      this.form[field] = ''
    },
    formReinit () {
      this.form = {
        deviceValue: '',
        twoFaValue: '',
        emailValue: '',
      }
    },

    deviceReinit () {
      if (this.device && this.deviceCodeResendIn != null) {
        this.resetResendDeviceConfirmationCodeButton(this.deviceCodeResendIn)
      }
    },

    emailReinit () {
      if (this.email && this.emailCodeResendIn != null) {
        this.resetResendEmailConfirmationCodeButton()
      }
    },

    // TODO: Refactor
    resetResendDeviceConfirmationCodeButton (
      leftSeconds = this.deviceCodeResendIn,
    ) {
      this.resendLeftSeconds = leftSeconds
      if (this.resendDeviceConfirmationCodeInterval) {
        clearInterval(this.resendDeviceConfirmationCodeInterval)
      }
      this.resendDeviceConfirmationCodeInterval = setInterval(() => {
        if (this.resendLeftSeconds <= 0) {
          this.resendLeftSeconds = 0
          clearInterval(this.resendDeviceConfirmationCodeInterval)
          return
        }
        this.resendLeftSeconds -= 1
      }, TIME.SECOND)
    },
    resendDeviceConfirmationCode () {
      if (this.resendLeftSeconds && this.deviceCodeResendIn != null) return
      this.$emit(
        'resend-device-code',
        this.resetResendDeviceConfirmationCodeButton,
      )
    },

    resetResendEmailConfirmationCodeButton () {
      this.resendEmailLeftSeconds = this.emailCodeResendIn

      if (this.resendEmailConfirmationCodeInterval) {
        clearInterval(this.resendEmailConfirmationCodeInterval)
      }

      this.resendEmailConfirmationCodeInterval = setInterval(() => {
        if (this.resendEmailLeftSeconds <= 0) {
          this.resendEmailLeftSeconds = 0
          clearInterval(this.resendEmailConfirmationCodeInterval)
          return
        }

        this.resendEmailLeftSeconds -= 1
      }, TIME.SECOND)
    },

    resendEmailConfirmationCode () {
      if (this.resendEmailLeftSeconds && this.emailCodeResendIn != null) {
        return
      }

      this.$emit(
        'resend-email-code',
        this.resetResendEmailConfirmationCodeButton,
      )
    },
  },
}
</script>

<style lang="scss" scoped>
.confirmation-page {
  $root: &;
  display: grid;
  width: 100%;
  max-width: toRem(392px);

  &__expanded-block {
    display: none;
  }

  &__form:not(:last-child) {
    margin-bottom: 30px;
  }

  &__input-wrapper,
  .img-wrapper {
    flex: 1;
    display: flex;
    align-items: center;
    overflow-x: hidden;
    width: 100%;
    flex-direction: column;
  }

  &__text {
    display: flex;
  }

  .img-wrapper {
    @include bp(0, l) {
      display: none;
    }
  }
}
</style>
