<template>
  <div
    class="BaseValidationError txt txt--12px mt-4 txt--color-07"
    :class="{'is-active': $attrs.error || !!error, 'is-small': small}"
  >
    <span class="message">{{ $attrs.error || error }}</span>
  </div>
</template>

<script>
export default {
  // is used for old input blocks
  name: 'BaseValidationError',
  props: {
    formKey: {
      type: String,
      default: '',
    },
    field: {
      type: String,
      default: '',
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    error () {
      if (this.formKey && this.field) {
        return this.$store.getters[`${this.formKey}/error`](this.field)
      }
      return ''
    },
  },
}
</script>
<style lang="scss">
.BaseValidationError {
  $shift: 4px;

  min-height: 22px;
  overflow: hidden;
  padding-bottom: $shift;

  span {
    font-family: 'Open Sans', sans-serif;
  }

  &.is-small {
    min-height: 10px;
    //min-height: 9px;
    // @include font(12);
  }

  &__message {
    display: block;
    opacity: 0;
    transition: all 0.2s cubic-bezier(0.44, 0.13, 0.31, 1.23);
    transform: translateY(-100%);
  }

  &.is-active & {
    &__message {
      opacity: 1;
      transform: translateY($shift);
    }
  }
}
</style>
