<template>
  <div class="two-fa-six-digits-input">
    <div class="two-fa-six-digits-input__inputs">
      <two-fa-input
        v-for="(el, ind) in inputs"
        :ref="el.name"
        :key="el.name"
        :value="el.value"
        v-bind="$attrs"
        :has-error="hasError"
        class="two-fa-six-digits-input__input txt txt--color-03 txt--16px txt--18px--m txt--center txt--bold"
        @input="onInput(ind, ...arguments)"
        @keyup.delete="onDelete(ind, ...arguments)"
        @keyup.left="moveLeft(ind, ...arguments)"
        @keyup.right="moveRight(ind, ...arguments)"
        @wheel.prevent.stop
      />
    </div>
  </div>
</template>

<script>
import { wait } from '@/lib/utils'

export default {
  name: 'TwoFaSixDigitsInput',
  components: {
    TwoFaInput: () =>
      import(
        /* webpackMode: "eager" */ '@/components/inputs/auth/TwoFaInput__old.vue'
      ),
  },

  props: {
    value: { type: String, default: '' },
    hasError: { type: Boolean, default: false },
  },

  data () {
    return {
      inputs: [
        { value: '', name: 'one' },
        { value: '', name: 'two' },
        { value: '', name: 'three' },
        { value: '', name: 'four' },
        { value: '', name: 'five' },
        { value: '', name: 'six' },
      ],
    }
  },

  watch: {
    hasError (newVal) {
      if (newVal) {
        this.inputs = this.inputs.map(({ name }) => ({
          value: '',
          name,
        }))

        this.$emit('input', '')
      }
    },
  },

  async mounted () {
    await wait(555)
    // this.$refs.one[0].$el.focus()
  },

  methods: {
    moveLeft (ind, _ev) {
      if (ind === 0) return
      this.$refs[this.inputs[ind - 1].name][0].$el.focus()
    },
    moveRight (ind, _ev) {
      if (ind === 5) return
      this.$refs[this.inputs[ind + 1].name][0].$el.focus()
    },
    onDelete (ind, ev) {
      const val = ev.target.value

      // if after delete there is still value
      // do nothing
      if (val.length > 0) return

      // change value
      this.inputs[ind].value = val

      if (ind > 0) {
        // go to previous
        const prevInputName = this.inputs[ind - 1].name
        this.$refs[prevInputName][0].$el.focus()
      }
    },
    onInput (ind, ev) {
      const val = ev.target.value
      this.$emit('input', val)
      // for deletion handle in onDelete
      const key = ev.keyCode || ev.charCode
      if (key === 8 || key === 46) return

      // if not number do nothin
      if (isNaN(parseFloat(val))) return

      // if user copied input with 6 digits
      // fill all values in all fields
      if (val.length === 6) {
        val.split('').forEach((v, i) => {
          this.inputs[i].value = ''
          this.inputs[i].value = v
        })
      } else {
        // replace value, with last character of value
        this.inputs[ind].value = ''
        this.inputs[ind].value = val.slice(-1)
      }

      // if not the last input
      if (ind + 1 < this.inputs.length) {
        // go to the next input
        const nextInputName = this.inputs[ind + 1].name
        this.$refs[nextInputName][0].$el.focus()
      } else {
        // try find empty input after last input and focus on it
        const emptyInput = this.inputs.find(inp => inp.value.length < 1)
        if (emptyInput) this.$refs[emptyInput.name][0].$el.focus()
      }

      // gather all values in one string of numbers and emit it
      const value = this.inputs.reduce((acc, v) => acc + v.value, '')
      if (value.length === 6) {
        // focus out from every input
        this.inputs.map(inp => this.$refs[inp.name][0].$el.blur())

        this.$emit('inserted', value)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.two-fa-six-digits-input {
  &__name {
    margin-bottom: 20px;
  }

  &__inputs {
    display: flex;
    justify-content: space-between;
    // padding-bottom: 30px;
  }

  &__input {
    // border-radius: 0px;
  }
}
</style>
